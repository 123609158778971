/*------------------------
Common Css
--------------------------*/
body{
    font-family: eb_garamond !important; font-weight: 400; 
}
.pad-l-r-0{
    padding-left:0px !important;
    padding-right:0px !important; 
}
.pad-l-0{
    padding-left:0;
}
.pad-r-0{
    padding-right:0;
}
.pad-t-0{
    padding-top: 0 !important;
}
.padd-t-50{
    padding-top: 50px;
}
.padd-b-50{
    padding-bottom: 50px;
}
.padd-40{
    padding-left: 40px;
    padding-right: 40px;
}
.padd-30{
    padding-top: 30px;
    padding-bottom: 30px;
}
.padd-15{
    padding-top: 15px;
    padding-bottom: 15px;
}
.padd-t-20{
    padding-top: 20px;
}
.pad-b-20{
    padding-bottom: 20px;
}
.pad-t-20{
    padding-top: 20px;
}
.m-b-50{
    margin-bottom: 50px;
}
.m-b-30{
    margin-bottom: 30px;
}
.m-t-20{
    margin-top: 20px;
}
.m-b-20{
    margin-bottom: 20px;
}
.opac0{
    opacity:0;
}
.opac1{
    opacity:1;
}
.page-content{ 
    clear:both;
}
.full-width{
    width: 100% !important;
    margin:auto;
}
/* .position-fixed{
    position: fixed;
    top: 100vh;
} */
.hidden-page{
    overflow: hidden;
}
.border-img{
    border: 1px solid #111;
    padding: 20px 0;
}
.arrow-up{
    background: url('/images/arrow-up.png');
    height: 40px;
    width: 40px;
    display: block;
    background-size: cover;
    margin: auto 80%;

}
.btn-top-to-page{
    background-color: #000;
    padding: 30px;
    text-align: right;
    position: relative;
    clear: both;
    border-top: 1px solid #aaa;
}

.img-gray-light{
    background-color:#f6f6f6;
}
.img-gray{
    background-color:#e6e6e6;
    
}
.img-gray-dark{
    background-color:#b7b7b7;
}
.img-gray-1{
    background-color:#929090;
}
.img-black{
     background-color:#000 !important;
}
.img-black-light{
    background-color:#6d6e71;
}
.img-black-1{
    background-color:#252525;
}
.img-black-2{
    background-color:#363636;
}
.img-black-3{
    background-color:#555555;
}
.img-light-brwn{
    background-color: #c2b59b;
}
.dropdown-backdrop{
    display: none !important;
}
.icon-angle-right{
    vertical-align: sub;
    margin-right: 8px;
}
.copy-right-text a{
    color: #fff;
    text-decoration: none;

}
.copy-right-text a:hover{
    color: #fd7903;
}
.titleConditions{
    margin: 10px 0 25px;
}
.titleConditions .heading{
    font-size: 40px;
}
/*------------------------
Header
--------------------------*/
.wrapper {
    float: left;
    width: 100%;
    background: url(/images/arm_bg.png) top center no-repeat;
    height: 100%;
    background-size: cover;
}

header {
    background: rgba(244, 244, 242, 0.7);
    float: left;
    width: 100%;
    height: 93px;
    position: relative;
    top: 0;
    z-index: 999;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.headerCover{
    height: 93px;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
header .nav-content{
     margin: 26px auto 26px;
     transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
header .top-menu {
    text-align: right;

}
header .top-menu button{
    background-color: transparent;
    border: none;
    outline: none;
}
header .top-menu .menu-icon{
    font-size:24px;
    vertical-align: middle;
}
header .top-menu .menu-item a:hover, header .top-menu .menu-item a:focus, header .top-menu .menu-item a:active{
   text-decoration: none !important;
}
header .top-menu .lanuageOption{
    min-width: 80px;
    text-align: left;
}
header .top-menu span.language{
    color: #333;
    position: relative;
}

header .top-menu .angle-down-size{
    font-size:22px;
    color: #9a9a9a;
    /* margin-left:-2px; */
    vertical-align: top;
    position: absolute;
    top: -3px;
}
.open > .dropdown-menu.languageMenu{
    display: inherit;
}
.dropdown-menu.languageMenu{
    min-width: 100px;
    background-color: #f7f7f7;
    border-radius: 2px;
    border-top: 0;
    padding: 0;
    display: inherit;
    height: 0;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    opacity: 0;
}
.lanuageOption.open .dropdown-menu.languageMenu{
    height: 60px;
    /* overflow: visible; */
    transition: all 0.4s ease-in-out;
    opacity: 0.93;
    padding: 2px 0;
}
.dropdown i{
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
}
.dropdown.open i{
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
}
.dropdown.open i.icon-arrows-down{
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: -8px;
}
.dropdown-menu.languageMenu li a{
    padding: 3px 12px;
    color: #000;
    margin: 1px 0   ;
}
.dropdown-menu.languageMenu li a:hover{
    background-color: #f2f2f2;
    color: #fd7903;
    transition: all 0.3s ease-in-out;
}

.sub-menu{
    position:absolute;
    top:93px;
    background: #ffffff;
    opacity: 0.97;
    width:100%;
    z-index: 99;
    transition: all 0.3s ease-in-out;
}
header.affix .sub-menu{
    top:60px;
    transition: all 0.3s ease-in-out;
}

.logo-mobile{
    background: url('/images/logo.png');
    background-repeat: repeat;
    background-size: auto auto;
    background-size: contain;
    background-repeat: no-repeat;
}
.sub-menu.hidden-menu{
    height: 0;
    overflow: hidden;
    transition: 0.5s all ease-in-out;
}
.sub-menu.hidden-menu.visible-menu{
    height: 400px;
    transition: 0.5s all ease-in-out;
}
.top-menu-heading{
    border-bottom: 2px solid #f78e55;
    padding: 40px 0 20px;
}
.top-menu-heading div {
    font-size: 17px;
    font-weight:600;
    letter-spacing: 1px;
}
.top-menu-content ul li{
    margin-bottom: 3px;
}
.top-menu-content ul li a{
    color:#000;
    font-size:18px
        
}
.top-menu-content ul li a:hover, .top-menu-content ul li a:focus, .top-menu-content ul li a:active{
     color:#fd7903;
    text-decoration: none;
}
.top-menu-content{
    padding: 20px 0 60px;
}
.logo-mobile{
    background: url('/images/Armas_logo_white_2018.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 15px 0;
}
.logo-mobile img{
    opacity: 0;
}

/*------------------------
footer
--------------------------*/

footer{
    background-color: #464646;
    padding-top:40px; 
    padding-bottom:40px; 
}
.footer-left-items{
    padding-left: 0;
}
.footer-left-items li{
    list-style:none;
    /* margin-bottom: 1px; */
    color:#fff;
    text-align: left;
}

.footer-left-items li a{
    color: #e5e5e5;
    font-size: 16px;
}
/* .footer-left-items li a img { 
    max-width: 150px;
    height: auto;
} */
.footer-left-items li:first-child{
    margin-bottom: 16px;
    font-size: 17px;
    letter-spacing: -0.5px;
}
.btn-contact{
    background: #000 !important;
    border: none !important;
    color: #fff !important;
    padding: 9px 35px !important;
    border-radius: 8px !important;
    outline: none !important;
    margin-top: 10px;
}
.btn-contact:hover, .btn-contact:focus, .btn-contact:active {
     background: #303030 !important;
    color: #fff;
    transition: all 0.2s linear;
}
.footer-right-items{
    margin-top: 20px;
    padding-left: 0;
}
.footer-right-items li{
    list-style-type: none;
    color:#fff;
    margin-bottom:4px;
}
.footer-right-items li a{
    color:#fff;
    font-size:34px;
    
}
.footer-right-items li .fa-youtube-play{
    font-size:38px;
}
.footer-left-items li a:hover{
    color:#fd7903;
    text-decoration: none;
}
.footer-right-items li a:hover{
    color: #fd7903;
    transition: all 0.2s linear;
}
.footer-right-items.socialTwo{
   margin-left: 10px;
}
.footer-devider{
    border-bottom: 2px solid #aaa;
    padding-bottom: 35px;
}
footer .copy-right-text{
    padding: 20px 0 25px 30px;
    color: #fff;
    font-size:16px;
}
.footer-right-items.list-inline li{
    padding: 0 10px;
}
/*------------------------
Home Page
--------------------------*/

.img-outer{
    width:auto;
    height:100%;
    position: relative;
   
}
.fit-image{
    position: relative;
}
.fit-image img{
    position: relative;
    width:auto;
    max-height:100%;
    margin: auto;
}
.fit-image.fit-large img{
    /* max-height: 550px; */
    /* max-width: 550px; */
    width: auto;
    
}
.img-caption {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    background: rgba(22,22,22,0.9);
    text-align: center;
    font-size: 18px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    height: 100%;
}
.fit-image:hover .img-caption{
    opacity: 1;
    transition: all 0.5s ease-in-out;
     cursor:pointer;
}
.img-caption > div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.btn.btn-discover{
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-size: 24px;
    border-radius: 10px;
    margin-top: 25px;
    /* width:125px; */
    outline: none;
    letter-spacing: 0.5px;
}
.btn-discover:hover , .btn-discover:focus, .btn-discover:active{
    background: transparent !important;
    color:white !important;
}
.image-text{
    position:absolute;
    bottom:8%;
    left:50%;
    font-size:29px;
    transform: translateX(-50%);
    letter-spacing: 1px;
    text-transform: uppercase;
}
.image-text.ring_text{
    bottom: 2%;
    font-size: 18px;
    text-transform: capitalize;
}
.image-text.torika-full-img{
    bottom:50%;
    left:40%;
    transform: translateX(-40%);
}
.text-white{
    color:#fff;
}
.canyon-pad{
    padding:50px;
}
.movie-pos{
    position: relative;
    padding-bottom: 56.25%;
    height: 100%;
    width: 100%;
}
.movie-pos-gallery{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
}
.videoArmas{
    position: absolute;
    top: 0;
    left: 0;
}
.videoCover {
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 99;
}
.videoOuter{
    width: 100%;
    height: 100%
}
.owlVideoCover{
    height: 100%;
}

.devider {
    border-bottom: 2px solid #f68e56;
}
/*------------------------
About Armas
--------------------------*/
.about-img{
    background: url(/images/commonBg.png) top right no-repeat;
}
.contact-img {
    background: url(/images/commonBg.png) top right no-repeat;
}
.news_item_img{
    background: url(/images/news_item.png) top right no-repeat;
}
.overview_news_img{
    background: url(/images/commonBg.png) top right no-repeat;
}
.aphrodite-bg{
    background: url(/images/aphrodite-bg.png) top right no-repeat;
}

.PageWrapper{
    float: left;
    width: 100%;
    background-size: cover;
    background-position: center center;
    height:40vh;
}
.content-wrapper{
    padding:  35px 30px;
}
.innovation-head p:first-child{
    margin-bottom: 20px;
    font-size: 55px;
    color: #d9d9d9;
}
.innovation-head p:first-child span{
    color: #000;
}
.innovation-head p{
    margin-bottom: 40px;
    font-size: 20px;
    text-align: center;
}
.founder-imem-text{
    padding: 5% 10% 5% 20%;
    text-align: justify;
    color: #fff;
}
.founder-imem-text p:first-child{
    margin-bottom: 40px;
    color: #f5f5f5;
    font-size: 26px;
    text-align: left;
}
.founder-imem-text p{
    margin-bottom: 25px;
    font-size: 17px;
    color: #f5f5f5;
}
.jwell-content{
    margin: auto;

}
.jwell-content p{
    font-size: 17px;
    text-align: justify;
}
.aboutLastWrap p{
    font-size: 20px;
}
 .jwell-content h1{
     font-size: 45px;
 }
/*------------------------
collection CSS
--------------------------*/

.product-wrapper-row [class*="col-sm-"]{
    margin: 15px 0;
}
.img-border{
    box-shadow: 0 0 3px #fafafa;
}
.img-info{
    text-align: center;
    color: #fff;
    padding: 15px 10px;
    font-size: 16px;
}
.img-info.text-blk{
    color: #000;
}
.product-detail div{
    padding: 20px 0;
    border-bottom: 1px solid #fff;
}
.product-detail.text-blk div {
    border-bottom: 1px solid #000;
}
.product-detail div:first-child p{
    font-size: 22px;
}
.product-detail div p{
    font-size: 16px;
    color: #fff;
}
.product-detail.text-blk div p{
    color: #000;
}
.product-detail button{
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 50px;
    outline: none !important;
    text-transform: uppercase;
    padding: 10px 50px;
}
.product-detail button:hover, .product-detail button:focus, .product-detail button:active{
    background: transparent !important;
    color: white !important;
}
.product-detail.text-blk button{
    border: 1px solid #000;
    color: #000;
}
.product-detail.text-blk button:hover, .product-detail.text-blk button:focus, .product-detail.text-blk button:active{
    background: transparent !important;
    color: #000 !important;
    border-color: #777 !important;
}
.ring-bottom{
    padding-bottom: 30px;
    border-bottom: 1px solid #f68e56;
}
.creative-heading{
    padding: 10px 0;
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #fff;
    text-align: center;
    clear: both;
}
.creative-heading.text-blk{
    color: #000;
}
.bg-arodite{
    background: url('/images/header-image.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.contact-detail .heading{
    font-size: 40px;
}
.contact-detail .paragraph{
    font-size: 22px;
}
.contact-detail .contactForm label{
    font-weight: 400;
    font-size: 18px;
}
.contact-detail .contactForm .inputContact{
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: #f5f5f5;
    min-height: 38px;
}
.contact-detail .btn-submit{
    font-size: 24px;
    width: 150px;
    border-radius: 0;
    color: #fff;
    background-color: #808080;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}
.btn-submit:hover, .btn-submit:focus { 
    background-color: #101010;
    transition: all 0.3s linear; 
}

/* .contact-detail ul li {
    margin-bottom: 10px;
    font-size: 20px;
}
.contact-detail ul li a{
    color: #000;
    text-decoration: none;
}
.contact-detail ul li a:hover{
    color: #fd7903;
    text-decoration: none;
} */
.breadcrumb.jeweleryNews{
    background-color: transparent;
    padding-left: 0;    
}
.jeweleryNews li a{
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
    color: #000;
}
.breadcrumb.jeweleryNews > li + li::before{
    color: #000;
    content: "|";
    font-size: 20px;
    padding: 0 8px;
}
.PreNextPost .pager li > a {
    border: 1px solid transparent;
    color: #000;
    font-size: 20px;
}
.PreNextPost .pager li > a:hover,.PreNextPost .pager li > a:focus {
    background-color: transparent;
    color: #fd7903;
}
.jwelereyNewsTxt .BlowCptureTxt p{
    text-align: center;
}
.jwelereyNewsTxt p{
    font-size: 19px;
    margin-bottom: 25px;
    /* text-align: justify; */
    line-height: 1.6;
}


.jwelereyNewsTxt .caption{
    margin: 15px 0;
    font-size: 15px;
}
.jwelereyNewsTxt .BlowCptureTxt{
    text-align:center;
}
.jwelereyNewsTxt .BlowCptureTxt h2{
    margin: 40px 0;
}
.socialStripeImg > .StripeTxt {
    font-size: 22px;
    margin-bottom: 15px;
}
.socialStripeImg > .stripe-icons{
   width: 202px;
    margin: auto;
}
.socialStripeImg .stripe-icons ul{
    display: flex;
    justify-content: space-between;
}
.socialStripeImg .stripe-icons ul li a span{
    height: 45px;
    width: 45px;
    display: block;
}
.stripe-lnkdin{
    background: url('/images/stripe-social.png') no-repeat;
}
.stripe-twiter{
    background: url('/images/stripe-social.png') no-repeat;
    background-position: 48% 0;
}
.stripe-fb{
    background: url('/images/stripe-social.png') no-repeat;
    background-position: 100% 0;
}
 .individual_head_item h2{
    font-weight: 400;
    font-size: 40px;
    letter-spacing: -1px;
    color: #000
}
.NewsHeading{
    padding: 10px 100px 30px;
}
.NewsOuterdetail{
    border-bottom:  1px solid #f26c4f;
    padding-bottom: 15px;
}
.NewsOuterdetail p{
    font-size: 18px;
}
.loadNewsMore{
    font-size: 28px;
    color: #000;
}
.loadNewsMore:hover, .loadNewsMore:hover:focus {
    text-decoration: none;
    color: #fd7903;
}
.centerTitle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.aphroDiteContentrow [class*="col-sm"] {
    min-height: 330px;
    position: relative;
}
.aphrodite_jwel{
    position: absolute;
    z-index: 9;
}
.aphrodite_jwel .jwelery-image{
    margin-top: -150px;
    margin-left: -60px
}
/*------------------------
abracadabra-collection-variant
--------------------------*/
.abracadbraMainOuter{
    position: relative;
}
.abracadabra_collection{
    background: url(/images/armas-model-2-bg.png) center center no-repeat;
}
.aphrodite_collection_overview_new{
    background: url(/images/aphrodite-bg_newcollection.png) center center no-repeat;
}
.abracadabraWrapper .image-wrapper{
    /* float: left; */
    /* width: 100%; */
    
    height: 100vh;
    background-size: cover;
}
.abracadabraWrapper .text-wrapper{
    height: 100vh;
    background: #000;
    position: relative;
}
.abracadabraWrapper  .text-wrapper .AbraCollectionInfo{
    position: absolute;
    top: 50%;
    transform: translateY(-35%);
    padding-left: 61px;
    max-width: 390px;
    padding-right: 20px;
}
.AbraCollectionInfo  p{
    /* text-align: justify; */
    font-size: 19px;
    margin-top: 20px;
}

.categoriesminHeig.AbraCollectionInfo  p{
    /* text-align: justify; */
    font-size: 19px;
    margin-top: 20px;
}ht [class*="col-sm"]{
    min-height: 300px;
    /* width: 100%; */
    position: relative;
}
.smallCaptionDetail {
    text-align: center;
    font-size: 20px;
    padding-bottom: 20px;
}
.categoryDeatail{
    font-size: 25px;
    padding: 30px 0 15px;
}
.categoryDeatail .baraceletCaption{
    position: absolute;
    bottom: 0;
    left:30%;
}
.braceleteImg{
    margin-top: 80px;
}
.image-thumbnail{
    border: 1px solid #f1f1f1;
    border-radius: 4px;
}
.upperSpaceEarrings{
    height: 140px;
}
.Earring_demo img{
    height: 100%;
    width: auto;
    position: relative;
}
.Earring_demo span{
    font-size: 20px;
    color: #fff;
    position: absolute;
    bottom: 26%;
    left: 10%;
}
.dashedBorder{
    border: 1px dashed #fff;
}

.Mainn_Heading{
    padding: 22px; 
    text-align: center;
    background-color: #c2b59b;
}
.Mainn_Heading p{
    font-size: 28px;
    letter-spacing: 2.5px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
}
.modelImage{
    background: url("/images/picture_model_home.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.CategotyInfo {
    padding: 10px 0 30px;
    position: relative;
    text-align: center;
} 
.CategotyInfo  .ring_text{
    font-size: 18px;
    letter-spacing: 1.5px;
}
.content-wrapper.about_armas_wrapper{
    padding: 35px 0 !important;
}
.aboutuscover{
    padding-right: 200px;
    padding-left: 200px;
}
.aboutuscover p {
    font-size: 18px;
}
.about-model-right{
    padding-right: 200px;
}
/*.AbraCollectionInfo  h1{
    max-width: 280px;
}*/
.column12, .column4, .column8, .columnXS {
    /* position: relative; */
    min-height: 1px;
}
.column12, .columnXS {
    float: left; 
    width: 100%;
}
.single_slide{
    outline: none;
}
.torikahomeimg{
    margin-right: 15% !important;
}
.d-flex{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    /* justify-content: flex-start; */
}
.colmnOuter, .columninner{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.columninner{
    width: 555px;
}

/*------------------------
Media
--------------------------*/

@media screen and (min-width: 1681px) {
    .wrapper {
        float: left;
        width: 100%;
        background: url(/images/arm_bg.png) top right no-repeat;
        height: 100%;
        background-size: cover;
    }
    .container-fluid {
        max-width: 1640px;
    }
     .container {
        min-width: 1540px;
    }
    .AbraCollectionInfo  p{
        text-align: justify;
        font-size: 22px;
        margin-top: 20px;
    }
    .aboutuscover{
        padding-right: 340px;
        padding-left: 340px;
    }
    .about-model-right{
        padding-right: 340px;
    }
    .abracadabraWrapper  .text-wrapper .AbraCollectionInfo{
        padding-left: 200px;
        max-width: 540px;
        padding-right: 20px;
    }
   .verticalycenter{
    position: absolute;
    top: 30%;
    left: 30%;
    transform: translate(-25%, -25%);
   } 
   .abracadabraWrapper{
    height: calc(80vh - 93px) !important;
    }
    .abracadabraWrapper .text-wrapper, .abracadabraWrapper .image-wrapper {
        height: 80vh;
    }
   
    .jwell-content h1 {
        font-size: 55px;
    }
    .jwell-content p{
        font-size: 21px;
    }
    .abracadabraWrapper .text-wrapper .AbraCollectionInfo{
        max-width: 590px;
    }
    .AbraCollectionInfo p{
        font-size: 25px;
    }
    .AbraCollectionInfo h1 {
     font-size: 45px;
    }
    .torikahomeimg{
        margin-right: 25% !important;
    }
    .columninner{
        width: 740px;
    }
    
}



@media screen and (min-width:992px) and (max-width:1260px) {
    .aphrodite_jwel.respoImghieght img{
        max-height: 370px;
    }
    .movie-pos-gallery{
        padding-bottom: 72.25%;
      
    }
    .torikahomeimg{
        margin-right: 10% !important;
    }
    
}
@media screen and (min-width:992px) and (max-width:1200px) {
    .columninner{
        width: 455px;
    }
}
@media screen and (min-width:992px) {
    .column4, .column8 {
        float: left;
    }
    .column4 {
        width: 33.33333333%;
    }
    .column8 {
        width: 66.66666667%;
    }
    
    .jwell-content .heritagePra {
        padding: 0 70px;
    }
    .innovation-head p.aboutwork{
        padding: 0 110px;
    }
    header .top-menu{
        margin-top: 9px;
    }
    header.affix .top-menu{
        margin-top: 0px;
    }
   
}
@media screen and (min-width:768px) and (max-width:992px) {
    .wrapper {
        float: left;
        width: 100%;
        /* background: url(/images/armas_tab.png) top right no-repeat; */
        height: 100%;
        background-size: cover;
    }
    .founder-imem-text{
        padding: 0px 20px;
    }
    .founder-imem-text h1{
        margin-bottom: 10px;
    }
    .founder-imem-text p{
        margin-bottom: 5px;
        font-size: 14px;

    }
    .aphrodite_jwel.respoImghieght img{
        max-height: 320px;
    }
    .jwell-content .heritagePra {
        padding: 15px 0;
    }
    .innovation-head p.aboutwork{
        padding: 0 30px;
    }
    .movie-pos-gallery{
        padding-bottom: 94.25%;
      
    }
    .abracadabraWrapper  .text-wrapper .AbraCollectionInfo{
        top: 50%;
        transform: translateY(-40%);
    }
    .AbraCollectionInfo  p{
        text-align: justify;
        font-size: 19px;
        margin-top: 20px;
    }
    .image-text{
        bottom: 3% !important;
        font-size:22px;
    }
    .image-text.torika-full-img{
        bottom: 15% !important;
        font-size:32px;
    }
    .abracadabraWrapper{
        height: calc(80vh - 93px) !important;
        }
        .abracadabraWrapper .text-wrapper, .abracadabraWrapper .image-wrapper {
            height: 80vh;
        }
        .columninner{
            width: 100%;
            margin: auto;
        margin-left: -15px;
            
        }
}
@media screen and (min-width: 768px) {
    .banner-text{
        float:left;
        position:absolute;
        top:37%;
        left:33%;
        transform: translate(-37%,-23%);
    }
    .banner-text h2{
        letter-spacing:12px;
        font-size: 40px;
        font-weight: 400;
        color: #000;
    }
    .EternalRingsDemo  img{
        margin-top: -140px;
    }
    .contactForm{
        width: 67%;
    }
    header.affix{
        height: 60px;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        width: 100%;
        box-shadow: 0 0 5px #090909;
    }
    header .logo{
        max-width: 176px;
        max-height: 100%;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        margin-left: 32px;
    }
    header.affix .logo{
       max-width: 150px;
       max-height: 100%;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        /* padding-top: 5px; */
    }
    
    header.affix .nav-content{
        margin: 15px auto 8px;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
    }
    header .top-menu {
        padding-right: 32px;
    }
    .videoSlider{
        position: relative;
        height: 350px;
    }
    .collection-container{
        padding-right: 45px;
        padding-left: 45px;
        padding-bottom: 140px;
    }
    .abracadabraWrapper{
        height: calc(100vh - 93px);
        position: relative;
        top: -93px;
    }
    .items-display{
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
    }
    .FounderImage{
        padding-right: 0;
    }
    .header .burger-container {
        position: relative;
        display: inline-block;
         cursor: pointer;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        -webkit-tap-highlight-color: transparent;
      }
       .header .burger-container #burger {
        width: 22px;
        height: 10px;
        position: relative;
        display: block;
        margin: -4px auto 0;
        top: 50%;
      }
       .header .burger-container #burger .bar {
        width: 100%;
        height: 3px;
        display: block;
        position: relative;
        background: #000;
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        -webkit-transition-delay: 0s;
                transition-delay: 0s;
      }
       .header .burger-container #burger .bar.topBar {
        -webkit-transform: translateY(0px) rotate(0deg);
                transform: translateY(0px) rotate(0deg);
                transition-delay: -4s;
      }
      .header .burger-container #burger .bar.mdlBar {
        -webkit-transform: translateY(3px) rotate(0deg);
                transform: translateY(3px) rotate(0deg);
      }
       .header .burger-container #burger .bar.btmBar {
        -webkit-transform: translateY(6px) rotate(0deg);
                transform: translateY(6px) rotate(0deg);
      }
      .header.menu-opened-lg .burger-container {
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
          }
           .header.menu-opened-lg .burger-container #burger .bar {
            -webkit-transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
            transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
            -webkit-transition-delay: 0.2s;
                    transition-delay: 0.2s;
          }
           .header.menu-opened-lg .burger-container #burger .bar.topBar {
            -webkit-transform: translateY(4px) rotate(45deg);
                    transform: translateY(4px) rotate(45deg);
                    /* background: #fff; */
          }
          .header.menu-opened-lg .burger-container #burger .bar.mdlBar {
            /* -webkit-transform: translateY(4px) rotate(45deg); */
                    /* transform: translateY(4px) rotate(45deg); */
                    display: none;
          }
           .header.menu-opened-lg .burger-container #burger .bar.btmBar {
            -webkit-transform: translateY(1px) rotate(-45deg);
                    transform: translateY(1px) rotate(-45deg);
                    /* background: #fff; */
          }
          #menu-btn{
              cursor: pointer;
              margin-right: 8px;
          }
          .about_owner{
            /* background: url('/images/founder-item-new.jpg'); */
            background-size: cover;
        }
        .about_owner img {
            opacity: 0;
        }
        .align-icon{
            text-align: right;
            
        }
}

@media screen and (max-width:1200px) {
    /* .wrapper {
        float: left;
        width: 100%;
        background: url(/images/armas_tab.png) top right no-repeat;
        height: 100vh;
        background-size: cover;
    } */
    header .nav-content{
     margin: 30px auto 0;
    }
    header {
        height: 80px;
    }
    .headerCover{
        height: 80px;
    }
    header .logo {
        width: 156px;
    }
    .container {
        max-width: 1000px;
    }
    .sub-menu{
        position:absolute;
        top:80px;
    }

    .aboutuscover {
        padding-right: 80px;
        padding-left: 80px;
    }   
    .about-model-right{
        padding: 0;
    }
   
}
@media screen and (max-width:767px) {
       
    .header {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background:rgba(244, 244, 242, 0.7);
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; 
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
        z-index: 999;
        overflow: hidden;
      }
      
       .header .burger-container {
        position: relative;
        display: inline-block;
         cursor: pointer;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        -webkit-tap-highlight-color: transparent;
      }
       .header .burger-container #burger {
        width: 20px;
        height: 12px;
        position: relative;
        display: block;
        margin: 4px auto 0;
        top: 50%;
        padding-bottom: 25px;
      }
       .header .burger-container #burger .bar {
        width: 100%;
        height: 3px;
        display: block;
        position: relative;
        background: #000;
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        -webkit-transition-delay: 0s;
                transition-delay: 0s;
      }
       .header .burger-container #burger .bar.topBar {
        -webkit-transform: translateY(0px) rotate(0deg);
                transform: translateY(0px) rotate(0deg);
                transition-delay: -4s;
      }
      .header .burger-container #burger .bar.mdlBar {
        -webkit-transform: translateY(3px) rotate(0deg);
                transform: translateY(3px) rotate(0deg);
      }
       .header .burger-container #burger .bar.btmBar {
        -webkit-transform: translateY(6px) rotate(0deg);
                transform: translateY(6px) rotate(0deg);
      }
      .header.menu-opened #fh5co-main-nav > ul {
        border-top: 1px solid #fff;
    }
    #fh5co-main-nav > ul {
        margin-top: 10px;
        padding-top: 10px;
    }
    #fh5co-main-nav > ul > li > a > i {
        float:  right;
        margin-right: 15px;
        font-size: 24px;
        color: #707070;

    }
        #fh5co-main-nav > ul > li > ul  i {
            font-size: 32px;
            color: #fff;
            padding-right: 10px;
    }
    
       .header ul.menu {
        position: relative;
        display: block;
        padding: 0;
        list-style: none;
        
      
      }
       .header ul.menu li.menu-item {
        -webkit-transform: scale(1.1) translateY(-20px);
                transform: scale(1.1) translateY(-20px);
                
        opacity: 0;
        height:0;
        -webkit-transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      }
       .header ul.menu li.menu-item a {
        display: block;
        position: relative;
        color: #e5e5e5;
        text-decoration: none;
        font-size: 17px;
        line-height: 1.8;
        width: 100%;
      }
      /* .header ul.menu li.menu-item a:hover{
         color:#fd7903;
      } */
       .header.menu-opened {
        min-height: 100vh;
        background-color: #929091;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transition-delay: 0.25s;
                transition-delay: 0.25s;
        overflow-y: scroll;
        
      }
       .header.menu-opened .burger-container {
        -webkit-transform: rotate(90deg);
        
                transform: rotate(90deg);
                
      }
       .header.menu-opened .burger-container #burger .bar {
        -webkit-transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        -webkit-transition-delay: 0.2s;
                transition-delay: 0.2s;
      }
       .header.menu-opened .burger-container #burger .bar.topBar {
        -webkit-transform: translateY(4px) rotate(45deg);
                transform: translateY(4px) rotate(45deg);
                background: #fff;
      }
      .header.menu-opened .burger-container #burger .bar.mdlBar {
                display: none;
      }
       .header.menu-opened .burger-container #burger .bar.btmBar {
        -webkit-transform: translateY(1px) rotate(-43deg);
                transform: translateY(1px) rotate(-43deg);
                background: #fff;
      }
       .header.menu-opened ul.menu li.menu-item {
        -webkit-transform: scale(1) translateY(0px);
                transform: scale(1) translateY(0px);
        opacity: 1;
        height:auto;
      }
       .header.menu-opened ul.menu li.menu-item:nth-child(1) {
        -webkit-transition-delay: 0.27s;
                transition-delay: 0.27s;
      }
       .header.menu-opened ul.menu li.menu-item:nth-child(2) {
        -webkit-transition-delay: 0.34s;
                transition-delay: 0.34s;
      }
       .header.menu-opened ul.menu li.menu-item:nth-child(3) {
        -webkit-transition-delay: 0.41s;
                transition-delay: 0.41s;
      }
       .header.menu-opened ul.menu li.menu-item:nth-child(4) {
        -webkit-transition-delay: 0.48s;
                transition-delay: 0.48s;
      }
       .header.menu-opened ul.menu li.menu-item:nth-child(5) {
        -webkit-transition-delay: 0.55s;
                transition-delay: 0.55s;
      }
       .header.menu-opened ul.menu li.menu-item:nth-child(6) {
        -webkit-transition-delay: 0.62s;
                transition-delay: 0.62s;
                padding-bottom: 4px;
      }
       .header.menu-opened ul.menu li.menu-item:nth-child(7) {
        -webkit-transition-delay: 0.69s;
                transition-delay: 0.69s;
                border-top: 1px solid #e5e5e5;
                padding-bottom: 4px;
      }
      .header.menu-opened ul.menu li.menu-item:nth-child(8) {
        -webkit-transition-delay: 0.76s;
                transition-delay: 0.76s;
                border-top: 1px solid #fff;
                padding-bottom: 4px;
      }
      .header.menu-opened ul.menu li.menu-item:nth-child(9) {
        -webkit-transition-delay: 0.83s;
                transition-delay: 0.83s;
                border-top: 1px solid #fff;
                padding-bottom: 4px;
      }
      .header.menu-opened ul.menu li.menu-item:nth-child(10) {
        -webkit-transition-delay: 0.90s;
                transition-delay: 0.90s;
                border-top: 1px solid #fff;
                padding-top: 15px;
      }

    /* New Add on style.css */
      .header.menu-opened ul.menu li.menu-item:nth-child(11) {
        -webkit-transition-delay: 0.97s;
                transition-delay: 0.97s;
                border-top: 1px solid #fff;
      }
      .header.menu-opened ul.menu li.menu-item:nth-child(12) {
        -webkit-transition-delay: 0.204s;
                transition-delay: 0.204s;
                border-top: 1px solid #fff;
      }
       .header.menu-opened .icon.icon-bag {
        -webkit-transform: translateX(75px);
                transform: translateX(75px);
        -webkit-transition-delay: 0.3s;
                transition-delay: 0.3s;
      }
      .menu-item.open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0 0 5px 10px;
    }
    .dropdown-menu li a{
        background-color: #a6a6a6;
        padding: 1px 9px;
        margin: 6px 0;
    }
    .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus{
        background: #a6a6a6;
    }
        .wrapper {
            float: left;
            width: 100%;
            /* background: url(/images/armas_small.png) top center no-repeat; */
            height: 100vh;
            background-size: cover;
            background-position: 63% 100%;
        }
        header {
            background: rgba(244, 244, 242, 0.7);
            float: left;
            width: 100%;
            height: 48px;
        }
        .headerCover{
            height: 48px;
        }
        header .logo {
            width: 100px;
        }
        header .nav-content{
             margin: 13px auto;
        }
        .mobile {
            display: block;
        }
        .banner-text.for_mobile{
           position: absolute;
            bottom: 0;
            width: 100%;
            background: #fff;
            left: 0;
            cursor: pointer;
            text-align: center;
            
        }
        .banner-text h2{
            letter-spacing:6px;
            margin-top: 10px;
        }
    footer {
        text-align: center;
        padding-bottom: 0;

    }
    /* .footer-left-items, footer-right-items{
        margin-bottom:50px;
    } */
    .NewsHeading{
        padding: 0;
    }
    .aphrodite_jwel{
       position:absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
    }
    .aphrodite_jwel .jwelery-image{
        margin: -130px auto 0;
    }
    .footer-right-items{
        margin-top: 0;
    }
    .categoryDeatail{
        padding-top: 0;
    }    
    .categoryDeatail .baraceletCaption{
        bottom: 20px;
        left:10%;
    }
    .braceleteImg{
        margin-top: 0px;
    }
    .aphrodite_jwel.respoImghieght img{
        height: 100%;
    }
    .jwell-content{
        margin-top: 15px;
    }
    .jwell-content .heritagePra {
        padding: 15px 0;
    }
    .logotelContact .footer-left-items li{
        text-align: center;
    }
    .image-text.torika-full-img{
        bottom:10%;
        left:50%;
        transform: translateX(-50%);
    }
    .abracadabraWrapper .image-wrapper{
        height: 300px;
    }
    .abracadabraWrapper .text-wrapper{
        height: 300px;
    }
    .abracadabraWrapper .text-wrapper .AbraCollectionInfo{
        position: absolute;
        top: -20px;
        transform: translateY(-24%);
        padding-left: 30px;
        max-width: 400px;
    }
    .innovation-head p:first-child{
        font-size: 40px;
    }
    .aboutuscover {
        padding-right: 15px;
        padding-left: 15px;
    }   
    .about-model-right{
        padding: 0;
    }
    .FounderImage{
        padding-left: 0;
    }
    .FounderImage img{
        width: 100%;
    }
    .AbraCollectionInfo  p{
        text-align: justify;
        font-size: 19px;
        margin-top: 20px;
    }
    .pad-in-small{
        padding-left: 0;
    }
    .FounderImage{
        margin-top: 15px;
        padding-right: 0;
    }
    .about-model-right > div {
        padding-right: 0;
    }
    .res-pad-l-0{
        padding-left: 0;
    }
    .res-pad-r-0{
        padding-right: 0;
    }
    .torikahomeimg{
        margin-right: 0 !important;
    }
    .columninner{
        width: 100%;
        margin: auto;
        margin-left: -15px;
    }
}
@media screen and (min-width:320px)  {
.image-text{
    font-size:19px;
}
}
@media screen and (min-width:480px) and (max-width:767px) {
    .wrapper {
        float: left;
        width: 100%;
        /* background: url(/images/armas_tab.png) top right no-repeat; */
        height: 100vh;
        background-size: cover;
    }
    .PageWrapper{
        height: 30vh;
    }
    .small-container{
        max-width: 500px;
        margin: auto;
    }
    .logotelContact{
        min-width: 29%;
        float: left;
    }
    .MenuList{
        min-width: 29%;
        float: left;
    }
    .categoryList{
        min-width: 29%;
        float: left;
    }
    .socialList{
        clear: both;
    }
   
    .videoSlider{
        position: relative;
        height: 300px;
    }.abracadabraWrapper .text-wrapper .AbraCollectionInfo{
        top: -34px;
    }
  
}
@media screen and (min-width:480px) and (max-width:640px) {
    .movie-pos-gallery{
        padding-bottom: 80%;
    }
    .Mainn_Heading p{
        font-size: 21px;
    }
}
@media screen and (max-width:479px){
    .banner-text h2{
        letter-spacing:3.8px;
    }
    
    .PageWrapper{
        height: 25vh;
    }
    .content-wrapper {
        padding: 35px 0px;
    }
    .align-icon{
        text-align: right;
    }
    .MenuList{
        width: 49%;
        float: left;
    }
    .categoryList{
        width: 49%;
        float: left;
    }
    .breadcrumb.jeweleryNews{
        padding-right: 0;  
    }
    .jeweleryNews li a{
        font-size: 16px;
    }
    .abracadabraWrapper .text-wrapper .AbraCollectionInfo{
       
        max-width: 300px;
    }
    .innovation-head p:first-child{
        font-size: 32px;
    }
    .innovation-head p{
        font-size: 17px;
    }
    .movie-pos-gallery{
        padding-bottom: 100%;
    }
    .founder-imem-text p:first-child{
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 1;
        text-align: initial;
    }
   
    .Mainn_Heading p{
        font-size: 21px;
    }
   
}
@media (max-width:800px) and (min-height: 800px){
    .wrapper {
        float: left;
        width: 100%;
        /* background: url(/images/armas_tab.png) 70% 0% no-repeat; */
        height: 100vh;
        background-size: cover;
        background-position: 60% 100%; 
    }
}   
