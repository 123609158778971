.main-title {
  font-size: 32px;
}
.menu-item1  .language{
  align-items: center;
  display: flex;
}
.sign-in {
    padding: 7px 31px !important;
    font-size: 17px !important;
    border-radius: 0px !important;
}
.menu-item1.dropdown.lanuageOption a{
  font-size: 16px !important;
   display: inline-block;
    padding-top: 2px;
}
.menu-item1 .dropdown-toggle .icon {
  line-height: 0px !important;
}
header .top-menu {
    padding-right: 10px !important;
}
.letter_space {
  letter-spacing: 0.7px;
}
.letter_space1 {
  letter-spacing: 0.7px;
}
.cart img {
  position: relative;
}
.badge {
  font-size: 10px;
  position: absolute;
  top: 14px;
  left: 14px;
  border-radius: 50px;
  padding: 4px 7px;
}
.lanuageOption.open .dropdown-menu.languageMenu {
  height: auto;
}
/* wishlist-page start */
div#EmptyWishList {
    display: none;
    font-size: 14px;
    height: 200px;
    padding-top: 20px;
    text-align: center;
}
/* wishlist-page end */
@media (max-width: 843px) {
  .Popup {
    right: 21px !important;
    top: 36px !important;
  }
}
@media (max-width: 767px) {
  .main-title {
    font-size: 28px;
  }
}
.lanuageOption.open .dropdown-menu.languageMenu {
  height: auto;
}
.dropdown-menu {
  right: 0px !important;
}
/*Signin-page*/
.section-wrapper {
  padding: 50px 0;
  overflow: hidden;
}
/*.center-border{
  width: 2px;
  height:400px;
  background-color: #c2b59b;
  margin: 0 auto;
}*/
/* .left, .right{
  padding:20px 120px 20px 120px;
} */

.left h2,
.right h2 {
  padding-bottom: 30px;
  line-height: 1.8;
  font-weight: 400;
}
.left a {
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  outline: none;
  background-color: #808080;
  text-align: center;
  padding: 10px 35px;
  font-size: 18px;
  line-height: 1.5;
}
.form-control {
  background-color: #f5f5f5;
  height: 40px;
  border-radius: 3px;
  border: 0px;
  box-shadow: none;
  margin-bottom: 25px;
}
.form-group label {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.btn {
  color: #ffffff;
  text-decoration: none;
  outline: none;
  background-color: #808080;
  text-align: center;
  padding: 10px 40px;
  font-size: 18px;
  border-radius: 0px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.btn:hover {
  background-color: #808080;
  color: #ffffff;
}
.forgot-pwd a {
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
}
@media (min-width: 992px) {
  .right:before {
    content: "";
    border-left: 1px solid #c2b59b;
    height: 100%;
    width: 10px;
    display: block;
    position: absolute;
    left: -25%;
  }
  .cart-head h2 {
    margin-left: 15px;
  }
}

/*Signin-page*/

/*Thankyou page*/
.Content-Section {
  padding: 50px 0;
  text-align: center;
}
.circle img {
  margin: 0 auto;
  max-width: 100px;
  width: 100%;
}
.Thankq-msg h2 {
  font-weight: 600;
  line-height: 1.8;
  margin: 20px 0;
  font-size: 26px;
}
.Thankq-msg p {
  font-weight: 400;
  font-size: 25px;
  line-height: 1.8;
  margin-bottom: 30px;
}
.Thankq-msg .Order-summary {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.7;
  margin-bottom: 40px;
  display: block;
  color: #000000;
  text-decoration: underline;
}
.Thankq-msg .Continue_shopping {
  color: #ffffff;
  text-decoration: none;
  outline: none;
  background-color: #808080;
  text-align: center;
  padding: 14px 45px;
  font-size: 25px;
  border-radius: 0px;
  display: inline-block;
  line-height: 1.7;
  letter-spacing: 0.5px;
}
/*Thankyou page*/

/*Cart_page*/
.Cart-details {
  padding: 50px 0;
}
.cart-head h2 {
  font-weight: 500;
  line-height: 1.8;
  margin-bottom: 30px;
  margin-top: 0px;
}
.Cart-details .col-sm-9 h2 {
  font-size: 28px;
}
.shopping_bag_wrap {
  display: flex;
  justify-content: space-between;
}
.shopping_bag_Left {
  width: 55%;
}
.shopping_bag_right {
  width: 25%;
}
.order_summary h3 {
  font-size: 26px;
}
.Sub_total h4,
.Total_item h4 {
  font-size: 22px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.Total_item .col-xs-6 h4 {
  opacity: 0.9;
}
.product-wrapper-row {
  display: flex;
  flex-wrap: wrap;
}
.CategotyInfo p{
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
}
.product {
  padding: 20px 10px;
  border-bottom: 1px solid #bebebe;
  border-top: 1px solid #bebebe;
}
.product img {
  max-width: 140px;
}
.account-tabs a:hover,.account-tabs a:focus {
  text-decoration: none;
}
.product h2 {
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 0px;
  color: #3b3b3b;
}
.product h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 0px;
}
.product .para {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 5px;
  color: #989898;
  font-weight: 500;
}
.product span {
  margin: 0px 20px;
  color: #989898;
}
.product .Remove_items {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #989898;
}
.Total_item,
.Free_shipping,
.order_summary h3 {
  margin-top: 0px;
  margin-bottom: 25px;
}
.Free_shipping p {
  margin: 0px;
  font-size: 18px;
}
.Sub_total {
  border-top: 1px solid #bebebe;
}
.align_right {
  text-align: right;
}
.Sub_total h4 {
  margin: 25px 0;
}
.order_summary a {
  color: #ffffff;
  text-decoration: none;
  outline: none;
  display: inline-block;
  background-color: #808080;
  text-align: center;
  padding: 10px 40px;
  font-size: 18px;
  border-radius: 0px;
  line-height: 1.5;
  margin-top: 10px !important;
}
/*Cart_page*/
/*WISHLIST_PAGE*/
.Wishlist_content {
  margin: 40px 0 60px 0;
}
.wishlisthead h2 {
  margin: 0px;
  padding-top: 40px;
}
.BoxWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 60px 0;
}
.box {
  width: 350px;
  border: 1px solid #bbbbbb;
  text-align: center;
  padding: 20px;
}
.box img {
  max-width: 300px;
  width: 100%;
}
.box:first-child {
  background-color: #ccc8c7;
}
.box:nth-child(2) {
  margin: 0 20px;
}
.col1 {
  position: relative;
}
.box a {
  text-decoration: none;
  color: #000000;
  outline: none;
  border: 0px;
}
.box p {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 500;
}
.text p {
  font-size: 19px;
  line-height: 1.5;
  color: #ffffff;
}
.text h4 {
  color: #ffffff;
}
.item_Name {
  margin-top: 10px;
  margin-bottom: 10px;
}
.image {
  position: relative;
  width: auto;
  max-height: 100%;
  margin: auto;
}

.middle {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background: rgba(22, 22, 22, 0.9);
  text-align: center;
  font-size: 18px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  height: 100%;
}

.col1:hover .image {
  opacity: 0.3;
}

.col1:hover .middle {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 280px;
  width: 100%;
}
.col1 .text .Hover-btn {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 10px;
  outline: none !important;
  letter-spacing: 0.5px;
  padding: 5px 15px;
  text-decoration: none;
  display: inline-block;
}
/*WISHLIST_PAGE*/

/* Listing_page */
.selectors select option a {
  color: #000000;
  text-decoration: none;
}
.selectors {
  padding: 40px 0 20px 0;
  display: flex;
  margin: 0px 10px;
}
.selectors .align_selector {
  display: inline-block;
}
.ListWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap:wrap;
}

.Item_box {
  width: 23.23%;
  border: 1px solid #111111;
  text-align: center;
  margin: 10px;
  padding: 30px 10px 10px 10px;
}
.Item_box img {
  max-width: 250px;
  width: 100%;
}
.Item_box a {
  text-decoration: none !important;
  outline: none;
  color: #000000 !important;
}
.Item_box p {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
}
.selectors .category .custom-select {
  margin-left: 20px;
}
select {
  /* background-image:url(../img/Down_arrow.png); */
  background-image: url(../../../images/Down_arrow.png);

  background-repeat: no-repeat;
  background-position: 90%;
  background-size: 10px;
  padding: 6px 8px;
  line-height: 1;
  border-radius: 5px;
  font-size: 15px;
  border-radius: 0px;
  -webkit-appearance: none;
  outline: none;
}
.product-link-section:hover{
  cursor: pointer;
 }
.like-products-head h2 {
  text-align: center;
  margin: 20px 0 30px 0;
  font-size: 26px;
  line-height: 1.5;
}
.img_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
}
.Item_img {
  width: 350px;
  text-align: center;
}
.Item_img img {
  max-width: 250px;
  width: 100%;
}

/* Listing_page */

/* Shipping_Page */
.Shipping_steps {
  padding: 50px 0;
}
.Tab-wrap {
  padding-bottom: 10px;
  border-bottom: 2px solid #888888 !important;
  text-align: center;
}
.Tab-wrap li {
  float: none !important;
  display: inline-block !important;
  margin: 0 30px;
}
.Shipping_steps .Tab-wrap li.active:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 4px;
  top: 50px;
  left: 0px;
  margin: 0 auto;
  background-color: #1f1f1f;
  -webkit-transition: all 0.3s ease 0s;
}
.nav-tabs > li > a {
  font-size: 16px;
  line-height: 1.4;
  color: #000000;
}
.nav-tabs > li.active > a {
  font-weight: 600;
  color: #000000;
}
.Outer-Wrap {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
}
.customer_detail,
.product_view {
  width: 41%;
}
.customer_detail h2 {
  font-size: 36px;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
}
.form-Wrap {
  margin: 40px 0;
}
.Title,
.Country,
.Form-text,
.Form-Label {
  width: 100%;
}
.Form-Label {
  /* margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5; */
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
}
.Title,
.Country {
  background-image: url(../../../images/Down_arrow.png);
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-size: 10px;
  width: 100%;
  padding: 0px;
  line-height: 1;
  font-size: 16px;
  border-radius: 0px;
  -webkit-appearance: none;
  outline: none;
}
.wrap_feild {
  margin-bottom: 40px;
  position: relative;
}
.Title:focus,
.Country:focus,
.Form-text:focus {
  outline: none;
}
.Title,
.Country,
.Form-text {
  border: 0px;
  border-bottom: 1px solid #1f1f1f;
}

.inputText {
  display: block;
  width: 100%;
  background-color: transparent;
  padding: 0;
  font-family: "Santral W01", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 1.71;
  min-width: 100%;
  color: #000;
  transition: border-color 0.2s ease-out;
}
.floating-label.Change_font {
  transform: translateY(-19px) scale(0.83);
  /* transform: translateY(-9px) scale(0.99);
  padding-bottom: 10px;
  font-size: 16px; */
}
.inputText:focus ~ label {
  transform: translateY(-19px) scale(0.83);
  /* transform: translateY(-9px) scale(0.99);
  font-size: 16px; */
}

.input-has-value {
  margin-bottom: 5px;
}
.floating-label {
  /* pointer-events: none;
  font-size: 20px;
  position: absolute;
  transition: 0.2s ease all;
  top:-2px; */
  pointer-events: none;
  font-size: 20px;
  position: absolute;
  transition: 0.2s ease all;
  top: -5px;
  transform-origin: left;
  backface-visibility: hidden;
}
.wrap_feild p {
  font-size: 16px;
  line-height: 1.5;
}
.letter_space1:hover {
    color: #fff;
    text-decoration: none;
}
.wrap_feild .continue {
  color: #ffffff;
  border:0px;
  text-decoration: none;
  outline: none;
  display: inline-block;
  background-color: #777;
  text-align: center;
  padding: 15px 40px;
  font-size: 24px;
  width: 100%;
  border-radius: 0px;
  line-height: 1.5;
  text-transform: capitalize !important;
}
.account-tabs a:hover, .top-content a:hover {
  color: #333333;
  text-decoration: none;
}
.wrap_feild .continue.hover-cap{
  text-transform: uppercase;

}

.img-outer {
  cursor: pointer;
}

.Exp_shipping {
  font-size: 22px;
  line-height: 1.5;
  margin: 10px 0;
}
.your_details {
  width: 100%;
  background-color: #f5f5f5;
  padding: 50px 25px 15px 25px;
}
.your_details .Heading {
  font-size: 26px !important;
  line-height: 1.5;
  padding-bottom: 15px;
  font-weight: 500;
}
.product_display {
  padding: 20px 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #777777;
}
.product_display .pro-Text {
  border-bottom: 1px solid #c3b59b;
  padding-bottom: 30px;
}

.product_display img {
  max-width: 250px;
  width: 100%;
}
.product_view_head {
  font-size: 19px;
  line-height: 1.5;
  color: #000000;
  font-weight: 500;
  padding-bottom: 35px;
}
.product_display p {
  margin: 0px;
}
.product_display .pro-feature {
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  opacity: 0.7;
}
.product_display .wrap-size .bold .pro-feature {
  font-weight: 500;
}
.product_display .col-sm-4:nth-child(2) {
  color: #a2a2a2;
}
.pro-price {
  padding-top: 20px;
}
.pro-price p {
  font-size: 20px !important;
  line-height: 1.5;
  color: #000000;
}
.pro-price .resp_left p {
  opacity: 0.8;
}
.bill {
  padding: 30px 10px 0 10px;
}
.bill p {
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
}
.bill .col-xs-6:first-child p {
  opacity: 0.8;
}
.bill .col-xs-6:first-child .bill-total {
  opacity: 1;
}
.all_info {
  margin-bottom: 0px;
}
.bill .bill-total {
  font-weight: 500;
  font-size: 20px;
}
.wrap-size {
  padding: 0px 30px;
}
.note {
  padding: 10px !important;
  margin-top: 20px;
}
.note p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0px;
  font-weight: 500;
}
.card-details {
  padding-bottom: 30px;
}
.Final-submit {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  line-height: 1.5;
  padding: 15px 20px;
  text-decoration: none;
  outline: none;
  margin-top: 20px;
  background-color: #e4e4e4;
}
.Final-submit:hover,
.Final-submit:focus {
  color: #ffffff;
  text-decoration: none;
}

/*input[type="checkbox"] {*/
/*     display: none;*/
/* }*/

/*#agree_terms {*/
/*    display: block !important*/
/*}*/

.wrap_feild input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: 99;
    width: 100%;
display: block !important
}
.checkbox-inline {
  position: relative;
  padding-left: 30px;
  font-size: 17px;
  color: #000000;
  line-height: 1.5;
}

.checkbox-inline::before {
  content: " ";
  width: 17px;
  height: 17px;
  position: absolute;
  visibility: inherit;
  border: 1px solid #000000;
  left: 0px;
  top: 4px;
}
input[type="checkbox"]:checked + .checkbox-inline::after {
  content: "\f00c";
  font-family: "FontAwesome";
  position: absolute;
  font-weight: 400;
  font-size: 11px;
  color: #000000;
  left: 3px;
  top: 5px;
}

/*.checkbox-inline:after{
  content: "\f00c";
  font-family: 'FontAwesome';
  position: absolute;
  left:3px ;
  top: 5px;
  font-weight: 900;
  font-size: 11px;
  color: #000000;
  visibility: inherit !important;
}
.checkbox-inline input[type="checkbox"]:checked:before{
  background-color: #8080808 !important;
}
.checkbox-inline input[type="checkbox"]:before{
  content: " ";
  width: 17px;
  height: 17px;
  position: absolute;
  visibility: inherit;
  border: 2px solid #808080;
  left: -1px;
  top: 0px;
} */
.form-Wrap ul {
  list-style: none;
  padding-left: 0px;
  margin: 0px;
}
.form-Wrap ul li {
  display: inline-block;
}
.form-Wrap ul li img {
  max-width: 40px;
  width: 100%;
  margin-right: 10px;
}
.order-Info {
  padding: 50px 0 30px 0;
}
.order-Info .wrap_feild:first-child {
  margin-bottom: 15px;
}
.customer_detail .wrap_feild:nth-child(15),
.customer_detail .wrap_feild:nth-child(16) {
  margin-bottom: 20px;
}
.order-Info p {
  font-size: 18px;
  line-height: 1.7;
  margin: 0px;
  color: #000000;
}
.Delivery_address {
  padding: 20px 25px 0 25px;
  border-top: 1px solid #c3b59b;
  display: flex;
  justify-content: space-between;
}
.Delivery_method {
  width: 50%;
}
.Change_info {
  padding: 0 25px;
}
.Delivery_address p {
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
}
.Delivery_address .D_Address {
  font-size: 17px;
  color: #000000;
  font-weight: 500;
}
.dull {
  opacity: 0.9;
}
.Change_info {
  text-decoration: none;
  color: #000000;
  font-size: 15px;
  position: relative;
  margin-top: 10px;
  display: block;
}
.Change_info:hover {
  text-decoration: none;
  color: #000000;
}
.Change_info::after {
  content: "\f105";
  font-family: "FontAwesome";
  position: absolute;
  font-weight: 400;
  margin-left: 5px;
}
/* Shipping_Page */
/* Adress_page */
.Account_steps {
  padding: 50px 0;
}
.Your_account {
  width: 25%;
}
.Address_section {
  width: 65%;
}
.account_optn p {
  font-size: 30px;
  line-height: 1.5;
  padding-bottom: 15px;
  margin: 0px;
  border-bottom: 1px solid #000000 !important;
  margin-bottom: 30px;
}

.Add-details,
.signin-info-optn .account_optn p {
  font-size: 30px;
  line-height: 1.5;
  padding-bottom: 10px;
  margin: 0px;
  border-bottom: 1px solid #000000 !important;
  margin-bottom: 30px;
}
.Account_steps .Address_section .form-Wrap {
  max-width: 70%;
}
.Account_steps .Address_section .form-Wrap .wrap_feild {
  margin-bottom: 40px;
}
.Account_steps .Address_section .form-Wrap .wrap_feild .continue {
  background-color: #808080;
  width: auto;
  border: none;
  padding: 12px 50px;
  font-size: 26px;
}
.Account_steps .Address_section .form-Wrap .wrap_feild:nth-child(12) {
  margin-bottom: 15px;
}
.Account_steps .Address_section .form-Wrap .wrap_feild .Title,
.Account_steps .Address_section .form-Wrap .wrap_feild .Country {
  background-position: 98% 0;
  background-size: 12px;
}
.account-tabs {
  margin-bottom: 15px;
}
.account-tabs a {
  text-decoration: none;
  color: #000000;
  font-size: 22px;
  line-height: 1.5;
}
.account-tabs a.active_now {
  font-weight: 500;
}
.instruction {
  font-size: 18px;
  margin: 20px 0;
  line-height: 1.5;
  font-weight: 600;
}
.Logout {
  display: flex;
  justify-content: space-between;
}
.top-content {
  width: 50%;
}
.top-content p,
.top-content a {
  font-size: 22px;
  line-height: 1.5;
  color: #000000;
}
.top-content a {
  text-decoration: none;
}
.top-content:nth-child(2) {
  text-align: right;
}
/* Adress_page */
/* Personal-info page */
.md-radio,
.md-checkbox {
  padding: 5px 0;
  line-height: 25px;
}
.visually-hidden,
.input-group input[type="radio"],
.input-group input[type="checkbox"],
.md-radio input[type="radio"],
.md-checkbox input[type="checkbox"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px !important;
}
.md-radio label:not(.error)::before,
.md-checkbox label:not(.error)::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  padding: 2px;
  border: 1px solid #000;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 10px;
  transition: box-shadow 0.3s ease-out;
}
.md-radio input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #c1b59b;
}
.md-radio label,
.md-checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
}
legend {
  border-bottom: 0px;
}
.md-radio label {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
}
.flex-block {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.md-input.flex-item {
  width: 47%;
  padding: 0 0 24px 0;
  position: relative;
  margin-right: 10px;
}
.md-input label {
  display: block;
  position: absolute;
  color: #767676;
  cursor: text;
  top: 0;
  left: 1px;
  backface-visibility: hidden;
  z-index: 0;
  transition: all 0.2s ease-out;
  transform-origin: left;
}
.md-input input,
.md-input textarea,
.md-input select {
  display: block;
  /* width: 100%; */
  position: relative;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
  font-family: "Santral W01", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 1.71;
  min-width: 150px;
  color: #000;
  caret-color: #00a4b7;
  background-position: 98%;
  margin-top: 10px;
  transition: border-color 0.2s ease-out;
  border-bottom: 1px solid #767676;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-row {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
}
/* Personal-info page */

/* Signin-information */
.Account_steps .signin-info .form-Wrap .wrap_feild .floating-label {
  top: -6px;
}
.signin-info .instruction {
  font-weight: 500;
}
/* Signin-information */

/* Order page */
.Order_item h2 {
  font-size: 30px;
  line-height: 1.5;
  padding-bottom: 15px;
  margin: 0px;
  border-bottom: 1.5px solid #939393;
  margin-bottom: 30px;
}
.Your_account .account_optn p {
  border-bottom: 1.5px solid #939393;
}
.Order_wrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
}
.order_info_wrap:first-child .Order_info:first-child{
	width: 10%;
	padding-top: 50px;
}
.Order_info {
  width: 50%;
}
.Order_info img {
  max-width: 120px;
  width: 100%;
}
.Order_info .Oreder_name {
  color: #000000;
  font-size: 17px;
  line-height: 1.5;
  opacity: 1;
  font-weight: 500;
}
.Order_info p {
  font-size: 15px;
  line-height: 1.5;
  opacity: 0.7;
  color: #000000;
}
.Order_wrap .order_info_wrap:nth-child(2) .Order_info:first-child {
  text-align: center;
  padding-top: 30px;
}
.Order_wrap .order_info_wrap:nth-child(2) .Order_info:first-child p {
  font-size: 18px;
  line-height: 1.5;
}
.Order_wrap .order_info_wrap:nth-child(2) .Order_info:first-child p span {
  font-weight: 600;
}
.Order_wrap .order_info_wrap:nth-child(2) .Order_info:nth-child(2) p {
  padding-top: 10px;
  opacity: 1;
}
.Order_wrap .order_info_wrap:nth-child(2) .Order_info:nth-child(2) p {
  font-size: 16px;
}
.order_info_wrap {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
/* Order page */
/* Forgot Password */

.forgot-pass h2{
 padding-bottom: 20px;
}
.btn.sign-in.form-submit-button:hover {
    background-color: #808080;
    color: #ffffff;
}
.forgot-pass .form-control:focus{
  box-shadow: none;
}
.forgot-pass{
  max-width: 450px;
  margin: 0 auto;
  padding: 60px 0;
}
.forgot-pass .form-group{
  margin-bottom: 40px;
}

/* Forgot Password */

/* Homepage menu */
.menu-item1  .language{
  align-items: center;
  display: flex;
}
.menu-item1.dropdown.lanuageOption a{
  font-size: 18px !important;
  color: #606060 !important;
   display: inline-block;
    padding-top: 2px;
}
.menu-item1 .dropdown-toggle .icon {
  line-height: 0px !important;
}
header .top-menu {
  padding-right: 40px;
}

@media screen and (min-width: 1680px) {
  .product_view {
    max-width: 550px;
  }
}
@media screen and (max-width: 1679px) and (min-width: 1200px) {
  .product_view {
    max-width: 420px;
  }
}
@media screen and (max-width: 1200px) {
  .wrap-size {
    padding: 0px;
  }
  .order_summary {
    padding-left: 0px;
  }
  .shopping_bag_right {
    width: 35%;
  }
}
@media screen and (max-width: 1024px) {
  .order_summary {
    padding-left: 80px;
  }
}

@media screen and (max-width: 992px) {
	/*Listing_page*/
	/*Listing_page*/
  /*Cart_page*/
  .product {
    /*overflow: hidden;*/
    margin-bottom: 40px;
  }
  .product .col-sm-3,
  .product {
    padding-left: 0px;
    padding-right: 0px;
  }
  .order_summary {
    padding-left: 0px;
  }
  .wishlisthead h2 {
    text-align: center;
    margin-left: 0px;
  }
  .ListWrapper {
    flex-wrap: wrap;
    align-items: center;
  }

  .Item_box {
    margin: 10px;
	width:45%;
  }
  .selectors {
    margin: 0px;
  }
  .img_wrap {
    flex-wrap: wrap;
    align-items: center;
  }
  .Item_img {
    margin: 10px auto;
  }
  .shopping_bag_wrap {
    flex-wrap: wrap;
    align-items: center;
  }
  .shopping_bag_Left,
  .shopping_bag_right {
    width: 100%;
  }
  /*Cart_page*/
  /* Shipping Page */
  .Outer-Wrap {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column-reverse;
  }
  .product_display .pro-Text {
    padding-bottom: 10px;
  }
  .customer_detail,
  .product_view {
    width: 100%;
  }
  .product_view {
    margin-bottom: 30px;
  }
  .pro-price .resp_left p {
    text-align: left;
  }
  .pro-price {
    padding-left: 0;
    padding-right: 0;
  }
  .wrap-size {
    padding: 0px 30px;
  }
  .order-Info {
    padding: 30px 0 10px 0;
  }
  /* Address page */
  .Your_account {
    width: 100%;
    margin-bottom: 30px;
  }
  .Account_steps .Outer-Wrap {
    flex-direction: initial;
  }
}
@media screen and (max-width: 768px) {
  .Cart-details {
    padding: 30px;
  }
  .cart-head h2 {
    margin-bottom: 30px;
  }
  .BoxWrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0;
  }
  .box {
    margin: 20px auto;
  }
  .box:nth-child(2) {
    margin: 20px auto;
  }
  .wishlisthead h2 {
    padding-top: 20px;
  }
  .Outer-Wrap {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 767px) {
	 .forgot-pass{
    padding: 40px 0;
  }

  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .Cart-details {
    padding: 30px 0;
  }
  /*Signin-page*/
  .header .burger-container #burger {
    padding: 20px 0;
  }
  /* .left, .right {
    padding: 20px 60px 20px 60px;
  } */
  /*Signin-page end*/

  .Thankq-msg .Continue_shopping {
    font-size: 18px;
  }
  .menu-opened .list-inline {
    margin-left: 0px;
    margin-top: 5px;
  }
  .section-wrapper {
    padding: 30px 0;
  }
  /*Signin-page*/
  .cart-head h2 {
    font-size: 28px;
  }
  .product h2 {
    font-size: 25px;
  }
  .product h3,
  .order_summary h3 {
    font-size: 20px;
  }
  .Cart-details .col-sm-9 h2 {
    font-size: 24px;
  }
  .Sub_total h4,
  .Total_item h4 {
    font-size: 18px;
  }
  .Thankq-msg .Order-summary {
    margin-bottom: 30px;
  }
  .Thankq-msg p {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .Thankq-msg h2 {
    font-size: 24px;
  }
  .left h2,
  .right h2 {
    margin: 0px;
    padding-bottom: 20px;
  }
  .section-wrapper .left {
    padding-bottom: 20px;
  }
  /* Shipping page */

  .wrap-size {
    max-width: 200px;
    padding: 0px;
  }
  .product_view_head {
    padding-bottom: 30px;
  }
  .bill {
    padding-bottom: 0px;
  }
  .product_display .pro-feature {
    padding: 0px;
  }
  .customer_detail h2 {
    font-size: 28px;
  }
  .Exp_shipping {
    font-size: 20px;
  }
  .customer_detail h2 {
    font-size: 28px;
  }
  .account-tabs {
    margin-bottom: 10px;
  }
  .account-tabs a {
    font-size: 20px;
  }
  .Add-details,
  .account_optn p {
    font-size: 28px;
  }
  .Account_steps .Address_section .form-Wrap .wrap_feild:nth-child(10) {
    margin-bottom: 50px;
  }
  .Account_steps .Outer-Wrap {
    padding: 20px 0;
  }
  .instruction {
    margin: 15px 0;
  }
  .Order_wrap {
    flex-wrap: wrap;
    align-items: center;
  }
  .order_info_wrap {
    width: 100%;
  }
  .Order_wrap .order_info_wrap:nth-child(2) .Order_info:first-child {
    text-align: left;
  }
  .bill .col-xs-6 {
    padding: 0px;
  }
  .product_display .wrap-size .col-sm-6:nth-child(2) .pro-feature {
    margin-left: 20px;
  }
  .Delivery_address .col-xs-12 {
    padding: 0;
  }
  .Delivery_address .col-xs-12:first-child {
    margin-bottom: 15px;
  }
  .Delivery_address {
    padding: 10px 10px 0px 10px;
  }
  .Change_info {
    padding: 0px;
  }
  .Add-details,
  .account_optn p {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 550px) {
  .flex-block {
    flex-wrap: wrap;
    align-items: center;
  }
   	.Item_box {
    margin: 10px auto;
	width:100%;
  }
}
@media screen and (max-width: 480px) {
	.forgot-pass{
    max-width: 100%;
  }

	header .top-menu {
		padding-right: 0px !important;
		padding-left:10px;
}
  .Account_steps .Address_section .form-Wrap {
    max-width: 100%;
  }
  .Account_steps {
    padding-bottom: 10px;
  }
  .Delivery_address {
    padding: 0px;
    flex-wrap: wrap;
    align-items: center;
  }
  .Delivery_method {
    width: 100%;
    margin: 10px auto;
  }
  .Change_info {
    padding: 0px;
    margin-top: 0px;
  }
  @media screen and (max-width: 400px) {
    select {
      background-position: 110px;
      width: 130px;
      font-size: 14px;
    }
    .Tab-wrap li {
      margin: 0 15px;
    }
    .order_info_wrap {
      flex-wrap: wrap;
      align-items: center;
    }
    .Order_info {
      width: 100%;
    }
  }
}

